import { render, staticRenderFns } from "./work-comparison-page.vue?vue&type=template&id=4224a58e&scoped=true&"
import script from "./work-comparison-page.vue?vue&type=script&lang=js&"
export * from "./work-comparison-page.vue?vue&type=script&lang=js&"
import style0 from "./work-comparison-page.vue?vue&type=style&index=0&id=4224a58e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4224a58e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KIcon from '@web/components/core/k-icon'
import KLayout from '@web/components/core/k-layout'
import KProductTitle from '@web/components/core/k-product-title'
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {KIcon,KLayout,KProductTitle,VTooltip})
